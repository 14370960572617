import React from "react";
import { ComponentProps } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { Link } from "../../elements/Link/Link";
import * as styles from "./Breadcrumbs.module.css";

interface Props extends ComponentProps {
  breadcrumbs?: { url: string; title: string }[];
}

export const Breadcrumbs: React.FC<Props> = ({ breadcrumbs, ...props }) => {
  if (!breadcrumbs) return null;

  return (
    <SectionWrapper {...props}>
      <nav className={styles.breadcrumbs} aria-label="breadcrumbs">
        <ol>
          {breadcrumbs.map((crumb, idx) => {
            if (idx === breadcrumbs.length - 1) {
              return (
                <li key={`keyName-${idx}`} aria-current="location">
                  {crumb.title}
                </li>
              );
            }
            return (
              <li key={`keyName-${idx}`} className={styles.separator}>
                <Link to={crumb.url} target="_self">
                  {crumb.title}
                </Link>
              </li>
            );
          })}
        </ol>
      </nav>
    </SectionWrapper>
  );
};
