import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Breadcrumbs } from "./Breadcrumbs";
import { KontentComponentSettings } from "../../../types";
import { PageMetadataContext } from "../../../lib/PageMetadataContext";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings {}

const BreadcrumbsContainer: React.FC<Props> = ({
  id,
  component_settings__hide_on_mobile,
  component_settings__anchor_name,
}) => {
  const breadcrumbs = useContext(PageMetadataContext).breadcrumbs;

  return (
    <Breadcrumbs
      breadcrumbs={breadcrumbs}
      id={id}
      anchor={component_settings__anchor_name.value}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
    ></Breadcrumbs>
  );
};

export default BreadcrumbsContainer;

export const fragmentBreadcrumbs = graphql`
  fragment Breadcrumbs on kontent_item_component___breadcrumbs {
    elements {
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
    }
  }
`;
